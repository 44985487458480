<template>
  <div class="commonAgreement_container">
    <div class="agreement_main">
      <div>
        <h1>服务协议</h1>
        <div class="agreement_underline">
          甲方：<span>{{ data.agreement_company_name }}</span>
        </div>
        <div class="agreement_underline">
          乙方：<span>{{ data.name }}</span>
        </div>
        <div class="agreement_margin_p">
          甲方委托乙方在 “
          <span class="agreement_underline"
            >肿瘤免疫治疗临床诊疗能力提升规范化项目</span
          >
          ”（以下简称“<span class="agreement_font_bold">本活动</span>
          ”）中于<span class="agreement_underline">线上平台</span
          >提供劳务。为明确双方义务及权利，
          经双方协商达成以下协议（以下简称“<span class="agreement_font_bold"
            >本协议</span
          >”）：
        </div>

        <h3>1. 权利与义务</h3>
        <p class="agreement_content_row">
          1.1乙方应当持有，且在本协议期限内都将持有安全、妥善及合法履行本协议规定劳务内容所需的所有适当的资质、执照和批准。
        </p>
        <p class="agreement_content_row">
          1.2乙方陈述并保证：（1）乙方在本协议下提供劳务已取得必要的批准且不会对其正常执业造成影响；（2）乙方签署本协议、提供本协议规定的劳务及收取相应的劳务费在目前和未来均不会导致乙方违反任何法律法规或其受雇的任何机构或实体的任何政策或程序；（3）其具备根据行业通行的专业标准提供本协议项下服务所需的专业知识；（4）其将根据行业惯常的专业标准以专业娴熟的方式履行服务；（5）其将遵守履行服务适用的所有法律、规定、法规和准则；及（6）其将遵守适用于提供附件所述服务的所有法律、规定、法规和准则。
        </p>
        <p class="agreement_content_row">
          1.3乙方应当按照本协议约定的条款与条件完成甲方委托的工作内容。基于乙方提供的工作内容，甲方应按照本协议约定在乙方的劳务完成后支付相应的劳务费。
        </p>
        <p class="agreement_content_row">
          1.4甲乙双方均应按照中国相关法律法规的规定，履行其各自在本协议下依据法律规定应当履行的与依法纳税相关的责任与义务，包括但不限于由甲方对乙方应缴的个人所得税履行代扣代缴义务，及应纳税人以合法的方式缴纳应缴的增值税。
        </p>
        <p class="agreement_content_row">
          1.5乙方保证其对本协议的履行不构成任何利益冲突，乙方就此特别陈述、保证并承诺如下：
        </p>
        <p class="agreement_content_row agreement_content_two_stage_title">
          （1）乙方不对任何第三方负有将以任何形式禁止或限制其履行本协议的任何义务，包括但不限于与其受雇的任何机构或实体、政府机关等之间基于合同、适用法律法规和/或政策程序而产生的利益冲突。
          前述“法律法规和/或政策程序”包括但不限于，禁止为获得或维持业务之目的而向政府官员行贿或付款的法律法规、禁止或者限制其履行本协议的相关法律法规政策等；
        </p>
        <p class="agreement_content_row agreement_content_two_stage_title">
          （2）乙方对本协议的履行，在目前和未来均不会导致其违反任何法律法规、政府机关规定和/或其受雇的任何机构或实体的任何政策或程序；
        </p>
        <p class="agreement_content_row agreement_content_two_stage_title">
          （3）乙方对本协议的履行，已取得所有必要的批准（包括但不限于其受雇的任何机构或实体的同意）且不会对其履行本职工作造成任何影响；且乙方在本协议期限及其前后的合理期间内（如法律法规有相关规定，以该规定为准），其并未参与且并未预见可能参与任何涉及本活动支持方广州百济神州生物制药有限公司上海分公司（“百济神州”）及其关联方（以下统称“百济神州集团”）的政府行为、行动或决策等，包括但不限于，对百济神州集团产品或服务采购、使用或准入相关决策提出意见或建议，或以其他合法方式对该决策流程产生影响的情况。
        </p>
        <p class="agreement_content_row agreement_content_two_stage_title">
          （4）乙方同意，如其在本协议期限及其前后的合理期间内（如法律法规有相关规定，以该规定为准），发现可能出现与本条上述第（1）项至第（4）项任何不一致的情形，乙方应当（1）就该等情况立即以书面方式通知甲方；（2）主动向相关机构披露与甲方及百济神州合作的情况；（3）根据相关机构的要求和指引适用回避流程，或者在获得相关机构同意无需回避的前提下，确保不受与甲方、百济神州之间合作的影响，客观、中立的提供意见与判断。
          尽管有前述约定，甲方保留以书面通知方式立即终止就本项目与乙方的合作（如适用）和/或采取其他适当的行动的权利，乙方应予以配合。
        </p>

        <p class="agreement_content_row">
          1.6甲方双方一致确认本协议项下的劳务费是合理的，并且反映所提供服务的公平市场价值。劳务费的提供完全不构成开出、供应、管理、推荐、购买、使用或出售任何商业产品的义务或诱导，且不以任何方式构成与商业实体或商业产品相关的任何业务机会或乙方就此作出或未来可能作出其他决定的条件。
        </p>
        <p class="agreement_content_row">
          1.7甲乙双方陈述并保证，其将遵守一切有关反贿赂及反腐败（《反贿赂法》）的适用法律、法令、法规和准则，包括但不限于美国《反海外腐败法》。
        </p>
        <p class="agreement_content_row">
          1.8甲方不得为以下之目的直接或间接向任何政府官员或《反贿赂法》中规定的其他人员、实体或机构，提供或支付有价值的物品：
        </p>
        <p class="agreement_content_row agreement_content_two_stage_title">
          1.8.1为任何商业实体获得或维持业务；
        </p>
        <p class="agreement_content_row agreement_content_two_stage_title">
          1.8.2不当地影响某一行为或决定而使商业实体获益；和/或
        </p>
        <p class="agreement_content_row agreement_content_two_stage_title">
          1.8.3为商业实体谋取不当利益。
        </p>
        <p class="agreement_content_row">
          1.9甲乙双方承诺保留准确透明的记录以反映交易与记录。若任何一方有任何理由相信另一方可能违反本条款，应当立即以书面形式告知另一方并与
          另一方协作对该事实情况进行调查和书面记录。
        </p>
        <p class="agreement_content_row">
          1.10对第1.5-1.9条的违反视为对本协议的实质性违反，守约方有权立即终止本协议。
        </p>
        <p class="agreement_content_row">
          1.11为传播医学知识或学术交流、或帮助医学教育次发达地区跟进医学发展之目的，甲方或其指定的第三方可能对乙方在本协议下提供服务的过程进行直播和/或录音录像拍照，并对乙方提供服务的过程进行直播、录播、回放和/或对录音录像拍照所取得的影像和声音记录进行编辑、改编、翻译、复制、展示或传播。乙方同意，直播以及录播回放录音录像和拍照内容可包括乙方的肖像和/或全名、教育背景、所服务的机构、职业经历等，并同意甲方或其指定的第三方有权以上述目的使用前述内容。在法律允许的范围内，甲方拥有与录音录像拍照所取得的影像和声音记录相关的所有版权和其他知识产权。
        </p>

        <p class="agreement_content_row">
          1.12本活动由第三方提供资金支持，为了遵循资金支持方的合规管理要求，甲方需要在必要范围内向资金支持方提供乙方在本协议项下提供服务的支持材料（例如：讲课照片、音视频、服务条款与条件、付款凭证等），当中可能包含乙方的敏感个人信息。资金支持方应当依法保障乙方的个人信息权益，乙方可通过https://www.beigene.com/privacy-policy/cn/查阅资金支持方的个人信息处理规则。
        </p>
      </div>

      <div>
        <h3>2.劳务及付款</h3>
        <p class="agreement_content_row">
          2.1乙方应提供之劳务的范围、劳务内容及计费标准见本协议《附件一》。
        </p>
        <p class="agreement_content_row">
          2.2乙方在按本协议要求提供劳务后，应按照本协议附件一的要求妥善填写《劳务确认表第一联》及《劳务确认表第二联》，并由本人在《劳务确认表第二联》相应位置签字并注明签字日期，之后该第二联的原件交还甲方存档。
        </p>
        <p class="agreement_content_row">
          2.3甲方在收到乙方提供的《劳务确认表第二联》后经审查无异议的，按本协议约定的方式向乙方支付相应的劳务费。
        </p>
        <p class="agreement_content_row">
          2.4甲方将劳务费以银行转账形式支付至下方乙方银行账户：
        </p>
        <p class="agreement_content_row">账户信息：</p>
        <div class="agreement_underline">
          姓名：<span>{{ data.name }}</span>
        </div>
        <div class="agreement_underline">
          联系方式：<span>{{ data.phone }}</span>
        </div>
        <div class="agreement_underline">
          身份证号码：<span>{{ data.id_card_number }}</span>
        </div>
        <div class="agreement_underline">
          开户银行（包括支行信息）：<span
            >{{ data.bank_name }}{{ data.bank_name_point }}</span
          >
        </div>
        <div class="agreement_underline">
          账号：<span>{{ data.bank_number }}</span>
        </div>
      </div>

      <div>
        <h3>3.协议的提前终止和终止后的影响</h3>
        <p class="agreement_content_row">
          3.1如乙方违反本协议第1.1条和第1.2条约定，甲方有权经书面通知后立即提前终止本协议。
        </p>
        <p class="agreement_content_row">
          3.2因本协议签署时依据的客观情况发生重大变化，致使本协议无法履行的，经任何一方书面通知后可提前终止本协议。
        </p>
        <p class="agreement_content_row">
          3.3任何一方单方面解除本协议的，应当提前30日以书面方式通知另一方，经双方协商后终止本协议。
        </p>
        <p class="agreement_content_row">
          3.4本协议提前终止后，乙方应当（1）向甲方指定的人员清晰交接与本协议下工作相关的事务；（2）乙方应立即停止于本协议下以甲方名义从事的活动。
        </p>
      </div>

      <div>
        <h3>4.法律适用和争议解决</h3>
        <p class="agreement_content_row">
          4.1本协议的订立、效力、解释、履行、终止和争议的解决均适用中国法律。
        </p>
        <p class="agreement_content_row">
          4.2因本协议而引起的或与本协议有关的一切争议，应当通过友好协商解决。如协商不成，任何一方可随时将该争议提交该方所在地有管辖权的法院通过诉讼途径解决。
        </p>
      </div>

      <div>
        <p>
          5.各方一致同意：对于（1）本协议，（2）各方之间的所有往来通讯，以及（3）一方向其他方提供的或从其他方收到的所有信息及其他材料（统称“<span
            class="agreement_font_bold"
            >保密信息</span
          >
          ”），除非披露保密信息的一方另行指示或法律另有规定，否则接收保密的各方应对保密信息应予以保密。
        </p>
      </div>

      <div>
        <p>
          6.乙方在任何情况下均不得出让、让与、转让或以其他方式转移其在本协议项下的任何权利或委任其在本协议项下的任何职责。此外，乙方亦不得向第三方分包或让与任何部分本协议项下的服务。当乙方获知其无法完成任何方面的服务时，应尽快告知甲方，并提交任何关于分包服务的书面请求，明确且详尽地描述需分包的服务内容，及接受乙方分包的第三方详情。尽管存在前述规定，乙方应始终对分包商的行为负责，并保证该等分包商遵守本协议条款。
        </p>
      </div>

      <div>
        <p>
          7.协议自甲方加盖公章且经乙方签字之日起生效，直至双方完全履行完毕其在本协议下的义务为止。
        </p>
      </div>

      <div>
        <p>8.本协议一式两份，甲、乙双方各执一份，具有同等效力。</p>
      </div>
    </div>
    <div class="agreement_sign_info">
      <div class="agreemtn_sign_them">
        <div class="agreement_sign_name">
          <span>甲方：</span>{{ data.agreement_company_name }}
        </div>
        <div class="agreement_sign_date">
          日期：{{ $tools.getDate(data.sign_time, "yyyy年MM月dd日") }}
        </div>
      </div>
      <div class="agreemtn_sign_owner">
        <div class="agreement_sign_name">
          <span ref="signData_one">乙方：</span>
          <customButton
            @click="doSignName(0)"
            v-if="!(data.meet_sign_name?.split(',')[0] || this.signData[0])"
          >
            <div>
              <span>签名</span>
            </div>
          </customButton>
          <img
            :src="data.meet_sign_name?.split(',')[0] || this.signData[0]"
            @click="doSignName(0)"
            v-else
            alt=""
          />
        </div>
        <div class="agreement_sign_date">
          日期：{{ $tools.getDate(data.sign_time, "yyyy年MM月dd日") }}
        </div>
      </div>
    </div>

    <div class="agreement_main attached_table">
      <div class="agreement_main attached_table">
        <div>
          <h1>《劳务协议》附件一</h1>
          <h3>
            【此处应以表格的方式列明乙方（1）提供服务的明细、（2）计价单位、（3）单位价格、（4）总价等信息】
          </h3>

          <p class="agreement_content_row">劳务费</p>
          <div class="agreement_table agreement_table_two">
            <div class="agreement_row">
              <div class="agreement_td">角色</div>
              <div class="agreement_td">会议主席</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">单价（人民币）</div>
              <div class="agreement_td">
                3000
                <div>代缴税后</div>
              </div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">具体工作内容</div>
              <div class="agreement_td">开场介绍人员/点评&总结</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">时长</div>
              <div class="agreement_td">全场主持</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">专家级别</div>
              <div class="agreement_td">
                <ul class="agreement_td_ul">
                  <li>主任/教授</li>
                  <li>
                    副主任/副教授且符合以下任意标准：
                    <div class="agreement_content_two_stage_title">
                      1、曾在国内核心期刊或国际期刊上发表3篇以上论文
                    </div>
                    <div class="agreement_content_two_stage_title">
                      2、国家级协会/学会任职（前任/现任或候任）
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="agreement_table agreement_table_two">
            <div class="agreement_row">
              <div class="agreement_td">角色</div>
              <div class="agreement_td">会议主席</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">单价（人民币）</div>
              <div class="agreement_td">
                2000
                <div>代缴税后</div>
              </div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">具体工作内容</div>
              <div class="agreement_td">开场介绍人员/点评&总结</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">时长</div>
              <div class="agreement_td">全场主持</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">专家级别</div>
              <div class="agreement_td">
                <ul class="agreement_td_ul">
                  <li>
                    副主任/副教授 但未能提供以下任意标准材料
                    <div class="agreement_content_two_stage_title">
                      1、曾在国内核心期刊或国际期刊上发表3篇以上论文
                    </div>
                    <div class="agreement_content_two_stage_title">
                      2、国家级协会/学会任职（前任/现任或候任）
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="agreement_table agreement_table_two">
            <div class="agreement_row">
              <div class="agreement_td">角色</div>
              <div class="agreement_td">会议演讲讲者</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">单价（人民币）</div>
              <div class="agreement_td">
                <div>2000（主治及以上，20分钟）</div>
                <div>1000（主治及以上，10-20分钟）</div>
                <div>1200（3年以上住院医师，20分钟）</div>
                <div>600（3年以上住院医师，10-20分钟）</div>
                <div>代缴税后</div>
              </div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">具体工作内容</div>
              <div class="agreement_td">问题&典型病例分享</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">时长</div>
              <div class="agreement_td">20分钟</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">专家级别</div>
              <div class="agreement_td">
                <ul class="agreement_td_ul">
                  <li>
                    主治及以上
                    （基金会可根据实际情况，纳入3年以上临床工作经验的住院医师，讲课费需对应降低）
                  </li>
                  <li>
                    分享I/O疑难学术问题及病例或见解：
                    <div class="agreement_content_two_stage_title">
                      1、存在肿瘤免疫临床应用相关的学术问题
                    </div>
                    <div class="agreement_content_two_stage_title">
                      2、愿意会前完善整理典型病例资料，供会议讨论
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="agreement_table agreement_table_two">
            <div class="agreement_row">
              <div class="agreement_td">角色</div>
              <div class="agreement_td">会议讨论/点评讲者</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">单价（人民币）</div>
              <div class="agreement_td">
                <div>1200（10分钟）</div>
                <div>600（5-10分钟）</div>

                <div>代缴税后</div>
              </div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">具体工作内容</div>
              <div class="agreement_td">综合讨论/点评</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">时长</div>
              <div class="agreement_td">5~10分钟</div>
            </div>
            <div class="agreement_row">
              <div class="agreement_td">专家级别</div>
              <div class="agreement_td">
                <ul class="agreement_td_ul">
                  <li>
                    主治及以上
                    （基金会可根据实际情况，纳入3年以上临床工作经验的住院医师）
                  </li>
                  <li>
                    分享I/O疑难学术问题及病例或见解：
                    <div class="agreement_content_two_stage_title">
                      1、存在肿瘤免疫临床应用相关的学术问题
                    </div>
                    <div class="agreement_content_two_stage_title">
                      2、愿意会前完善整理典型病例资料，供会议讨论
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement_main attached_table">
      <div>
        <h1>《劳务协议》附件二</h1>
        <h1>《劳务确认表》（示例）</h1>
        <h3>
          说明：本附件中的《劳务确认表》一式两份，第一联由乙方自行留存，第二联应在乙方按照《劳务协议》的要求提供完毕全部劳务后由其本人在签字处签名并注明签字日期，并将该第二联的原件交由甲方存档。
        </h3>
        <p class="agreement_content_row">《劳务确认表第一联》（乙方留存）</p>
        <div class="agreement_table agreement_table_two">
          <div class="agreement_row">
            <div class="agreement_td">项目编号</div>
            <div class="agreement_td">劳务内容</div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td"></div>
            <div class="agreement_td agreement_td_direction">
              以下“口”处打勾内容为已完成的具体工作内容：
            </div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td">1</div>
            <div class="agreement_td">
              <div>大会主席兼主持（大会开场发表致辞以及总结）</div>
              <div style="text-align: end" v-if="data.role_name == '主席'">
                <i class="iconfont icon-check"> </i> 已参与【1】场
              </div>
              <div style="text-align: end" v-else>
                <i class="iconfont icon-frame"> </i> 已参与【 】场
              </div>
            </div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td">2</div>
            <div class="agreement_td">
              <div>会议讲者（就学术专题发表演讲）</div>
              <div style="text-align: end" v-if="data.role_name == '讲者'">
                <i class="iconfont icon-check"> </i>
                已参与【1】场
              </div>
              <div style="text-align: end" v-else>
                <i class="iconfont icon-frame"> </i> 已参与【 】场
              </div>
            </div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td">3</div>
            <div class="agreement_td">
              <div>讨论嘉宾（参与会议讨论环节的讨论、点评）</div>
              <div style="text-align: end" v-if="data.role_name == '讨论'">
                <i class="iconfont icon-check"> </i> 已参与【1】场
              </div>
              <div style="text-align: end" v-else>
                <i class="iconfont icon-frame"> </i> 已参与【 】场
              </div>
            </div>
          </div>

          <div class="agreement_row">
            <div class="agreement_td"></div>
            <div class="agreement_td"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="commonAgreement_split_line">
      <div class="split_line"></div>
      <div>裁剪线</div>
      <div class="split_line"></div>
    </div>

    <div class="agreement_main attached_table">
      <div>
        <p class="agreement_content_row">《劳务确认表第二联》（交甲方存档）</p>
        <div class="agreement_table agreement_table_two">
          <div class="agreement_row">
            <div class="agreement_td">项目编号</div>
            <div class="agreement_td">劳务内容</div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td"></div>
            <div class="agreement_td">
              以下“口”处打勾内容为已完成的具体工作内容：
            </div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td">1</div>
            <div class="agreement_td">
              <div>大会主席兼主持（大会开场发表致辞以及总结）</div>
              <div style="text-align: end" v-if="data.role_name == '主席'">
                <i class="iconfont icon-check"> </i> 已参与【1】场
              </div>
              <div style="text-align: end" v-else>
                <i class="iconfont icon-frame"> </i> 已参与【 】场
              </div>
            </div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td">2</div>
            <div class="agreement_td">
              <div>会议讲者（就学术专题发表演讲）</div>
              <div style="text-align: end" v-if="data.role_name == '讲者'">
                <i class="iconfont icon-check"> </i> 已参与【1】场
              </div>
              <div style="text-align: end" v-else>
                <i class="iconfont icon-frame"> </i> 已参与【 】场
              </div>
            </div>
          </div>
          <div class="agreement_row">
            <div class="agreement_td">3</div>
            <div class="agreement_td">
              <div>讨论嘉宾（参与会议讨论环节的讨论、点评）</div>
              <div style="text-align: end" v-if="data.role_name == '讨论'">
                <i class="iconfont icon-check"> </i> 已参与【1】场
              </div>
              <div style="text-align: end" v-else>
                <i class="iconfont icon-frame"> </i> 已参与【 】场
              </div>
            </div>
          </div>

          <div class="agreement_row">
            <div class="agreement_td"></div>
            <div class="agreement_td"></div>
          </div>
        </div>
      </div>
    </div>

    <p style="margin-top: 1.25rem" class="agreement_content_row">
      本人确认已经按照《劳务协议》的要求如实、完整地提供了上表中所载明的各项劳务。
    </p>

    <div class="agreement_sign_info">
      <!-- <div class="agreemtn_sign_them">
                <div class="agreement_sign_name"><span>甲方：</span>{{data.project_agreement_company_name}}</div>
                <div class="agreement_sign_date">{{$tools.getDate(data.sign_time,"yyyy年MM月dd日")}}</div>
            </div> -->
      <div class="agreemtn_sign_owner">
        <div class="agreement_sign_name">
          <span ref="signData_twe">本人签字：</span>
          <customButton
            @click="doSignName(1)"
            v-if="!(data.meet_sign_name?.split(',')[1] || this.signData[1])"
          >
            <div>
              <span>签名</span>
            </div>
          </customButton>
          <img
            :src="data.meet_sign_name?.split(',')[1] || this.signData[1]"
            @click="doSignName(1)"
            v-else
            alt=""
          />
        </div>
        <div class="agreement_sign_date">
          签字日期：{{ $tools.getDate(data.sign_time, "yyyy年MM月dd日") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import customButton from "@/components/unit/CustomButton";
import { CircleCheck } from "@element-plus/icons";
import "../../../../assets/icon/iconfont.css";
export default {
  name: "commonAgreement",
  props: {
    agreementData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    agreementFields: {
      type: Object,
      default: () => {
        return [];
      },
    },
    signData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      data: {},
    };
  },
  components: {
    customButton,
    CircleCheck,
  },
  mounted() {
    this.initData(this.agreementData, this.agreementFields);
    setTimeout(() => {
      this.$emit("initScroll");
    }, 1000);
  },
  methods: {
    initData(data) {
      console.log(data);
      this.data = data;
    },
    verifyData() {
      if (!this.signData[0]) {
        let height = this.$refs.signData_one.offsetTop;
        this.$emit("scrollTo", height - 100);
        this.$tips.error({ text: "请先签署第一个签名" });
        return false;
      }
      if (!this.signData[1]) {
        let height = this.$refs.signData_twe.offsetTop;
        this.$emit("scrollTo", height - 100);
        this.$tips.error({ text: "请先签署第二个签名" });
        return false;
      }
      return true;
    },
    doSignName(index) {
      this.$emit("doSignName", index);
    },
  },
};
</script>
<style>
.commonAgreement_container {
  word-break: break-all;
}
.agreement_sign_info {
  display: flex;
}
.agreemtn_sign_them {
  margin-right: 0.25rem;
}
.agreement_sign_info > div {
  flex: 1 0 0;
  margin-top: 1.5rem;
}
.agreement_sign_name {
  height: 2.5rem;
  display: flex;
  align-items: center;
}
.agreement_sign_name span {
  white-space: nowrap;
}
.agreement_sign_name img {
  width: 6rem;
  height: auto;
}
.agreement_margin_p {
  margin: 0.75rem 0;
}
.agreement_font_bold {
  font-weight: 600;
}
.agreement_content_two_stage_title {
  text-indent: 1rem;
}
.attached_table {
  margin-top: 1.25rem;
}
.commonAgreement_container .agreement_table {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  margin-bottom: 2rem;
}
.commonAgreement_container .agreement_row {
  display: flex;
  min-height: 1.5rem;
}
.agreement_td {
  /* font-size: 4vw; */
  padding: 0.25rem 0.5rem;
  line-height: 1.5rem;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  word-break: break-all;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.commonAgreement_container .agreement_td div {
  line-height: 1.2rem;
}
.commonAgreement_container
  .agreement_table_one
  .agreement_row
  .agreement_td:first-child {
  flex: 1 0 0;
}
.commonAgreement_container
  .agreement_table_one
  .agreement_row
  .agreement_td:last-child {
  flex: 8.75rem 0 0;
  border-right: 0;
}
.commonAgreement_container
  .agreement_table_two
  .agreement_row
  .agreement_td:first-child {
  flex: 5.25rem 0 0;
}
.commonAgreement_container
  .agreement_table_two
  .agreement_row
  .agreement_td:last-child {
  flex: 1 0 0;
  border-right: 0;
}
.commonAgreement_container .agreement_row:last-child > .agreement_td {
  border-bottom: 0;
}
.commonAgreement_split_line {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  margin-top: 1.25rem;
}
.split_line {
  height: 0px;
  flex: 1 0 0;

  border-bottom: #000 0.25rem dashed;
}
.agreement_td_ul {
  padding-left: 1rem;
}
.agreement_td_direction {
  flex-direction: inherit !important;
  /* align-items: center; */
}
.icon-check {
  /* font-size: 1.5rem !important; */
}
</style>